import { Wrapper } from './EditSummaryPage.styled';
import { SummaryEditor } from './SummaryEditor';

import { convertFromRaw } from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import { useParams } from 'react-router-dom';

import { useGetAllSummariesQuery } from '@/api';
import { FullScreenLoader } from '@/components';

import './styles/customStyles.css';

export const EditSummaryPage = () => {
  const { playlistId, videoId } = useParams();

  const { data, isLoading } = useGetAllSummariesQuery(
    { playlistId, videoId },
    {
      skip: !playlistId || !videoId,
    },
  );

  return (
    <Wrapper>
      {isLoading && <FullScreenLoader />}

      {data && data?.results.length > 0 && (
        <SummaryEditor
          contentState={convertFromRaw(markdownToDraft(data.results[0]?.markdown || ''))}
          publicId={data.results[0]?.publicId}
        />
      )}
    </Wrapper>
  );
};
