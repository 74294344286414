import { Buttons } from './EditSummaryPage.styled';

import { useState } from 'react';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useUpdatePartialSummaryAdminMutation } from '@/api';
import { Button, RemoveSummary } from '@/components';
import { useActions } from '@/hooks';

export const SummaryEditor = ({
  contentState,
  publicId,
  isEmpty = false,
}: {
  publicId: string;
  contentState: ContentState;
  isEmpty?: boolean;
}) => {
  const { playlistId, videoId } = useParams();
  const { t } = useTranslation();
  const { showNotification, openModal } = useActions();

  const [value, setValue] = useState(() => EditorState.createWithContent(contentState));

  const [updateSummary, { isLoading }] = useUpdatePartialSummaryAdminMutation();

  const handleSaveSummary = async () => {
    try {
      // const content = value.getCurrentContent();
      // const rawObject = convertToRaw(content);
      // const markdownString = draftToMarkdown(rawObject);
      // console.log({ markdownString });
      if (isEmpty) {
        const rawObject = convertToRaw(value.getCurrentContent());
        const markdownString = draftToMarkdown(rawObject);
        await updateSummary({ publicId, body: { markdown: markdownString }, playlistId, videoId }).unwrap();
        return;
      }

      // console.log({ publicId, body: { markdown: markdownString }, playlistId, videoId });
      showNotification({
        text: t('save_timecodes', { ns: 'success' }),
        severity: 'success',
      });
    } catch (e) {
      showNotification({
        text: t('save_timecodes', { ns: 'error' }),
        severity: 'error',
      });
    }
  };

  const handleDeleteSummary = () => {
    openModal({ active: 'RemoveSummary' });
  };

  return (
    <>
      <Editor
        toolbarClassName="toolbarClassName"
        editorState={value}
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={setValue}
        editorStyle={{
          border: '2px solid #E4E4FF',
          borderRadius: '10px',
          padding: '12px 16px 14px 16px',
          marginTop: '20px',
          minHeight: '300px',
          maxHeight: '565px',
        }}
        toolbarStyle={{ background: '#140150', border: 'none', borderRadius: '10px', padding: '10px 20px ' }}
      />
      <Buttons>
        <Button theme="white" onClick={handleSaveSummary} disabled={isLoading}>
          Сохранить
        </Button>
        <Button onClick={handleDeleteSummary} disabled={isLoading}>
          Удалить
        </Button>
      </Buttons>
      <RemoveSummary publicId={publicId} videoId={videoId || ''} playlistId={playlistId || ''} />
    </>
  );
};
