import { api } from './api';

import type {

  BaseParams,
  GetList,
  QuizApiResponse,
} from '@/types';


const PATH = 'playlists';

export const quizAdminsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    //получаем квизы по ид видео
    getAllVideoQuizzes: build.query<GetList<QuizApiResponse>, { playlistId: string; videoId: string; params?: BaseParams }>({
      query: ({ playlistId, videoId, params }) => ({
        url: `${PATH}/${playlistId}/videos/${videoId}/quizzes/`,
        method: 'GET',
        params,
      }),
      providesTags: (_, __, { playlistId }) =>
        [{ type: 'control_panel_quiz', id: playlistId }, { type: 'video_quizzes', id: playlistId }],

    }),

    createQuiz: build.mutation<QuizApiResponse, { playlistId: string; body: Pick<QuizApiResponse, 'data'> }>({
      query: ({ playlistId, body }) => ({
        url: `${PATH}/${playlistId}/quizes/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (_, __, { playlistId }) => [
        { type: 'control_panel_quiz', id: playlistId },
      ]
    }),

    updatePartialQuizById: build.mutation<QuizApiResponse, { playlistId: string; publicId: string; body: Partial<Pick<QuizApiResponse, 'data'>> }>({
      query: ({ playlistId, publicId, body }) => ({
        url: `${PATH}/${playlistId}/quizes/${publicId}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, { playlistId }) => [
        { type: 'control_panel_quiz', id: playlistId },
      ],
    }),

    updateQuizById: build.mutation<QuizApiResponse, { playlistId: string; publicId: string; body: Pick<QuizApiResponse, 'data'> }>({
      query: ({ playlistId, publicId, body }) => ({
        url: `${PATH}/${playlistId}/quizes/${publicId}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, __, { playlistId }) => [
        { type: 'control_panel_quiz', id: playlistId },
      ],
    }),

    deleteQuiz: build.mutation<string, { publicId: string, playlistId: string }>({
      query: ({ playlistId, publicId }) => ({
        url: `${PATH}/${playlistId}/quizes/${publicId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { playlistId }) => [
        { type: 'control_panel_quiz', id: playlistId },
      ],
    }),

  }),
});

export const {
  useGetAllVideoQuizzesQuery,
  useCreateQuizMutation,
  useUpdatePartialQuizByIdMutation,
  useDeleteQuizMutation,
  useUpdateQuizByIdMutation,

} = quizAdminsAPI;

export const { getAllVideoQuizzes } = quizAdminsAPI.endpoints
