import { QuizBlock } from './components/QuizBlock';
import { QuizBlockWrapper, QuizPageStyledForm } from './EditQuizPage.styled';

import { Buttons } from '../EditTimecodesPage/EditTimecodesPage.styled';

import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useUpdatePartialQuizByIdMutation } from '@/api/quizAdmin';
import { FullScreenLoader, RemoveQuiz } from '@/components';
import { Button } from '@/components/Shared/Button';
import CrossIcon from '@/components/SvgIcons/CrossIcon';
import { useActions } from '@/hooks';
import { theme } from '@/styles';
import { QuizApiResponse, QuizFormState } from '@/types';
import { parseDuration } from '@/utils';

const transformToQuizFetchedData = ({ quizzes }: QuizFormState): Pick<QuizApiResponse, 'data'> => ({
  data: quizzes.map(({ chapter, quiz }) => ({
    chapter: { ...chapter, start: parseDuration(chapter.start as string) },
    quiz: quiz.map(({ question, answers }) => ({
      question,
      correctAnswer: answers.find(({ isCorrect }) => isCorrect)?.answer || '',
      wrongAnswers: answers.filter((answer) => !answer.isCorrect).map(({ answer }) => answer),
    })),
  })),
});

export const EditQuizForm = ({
  defaultValues,
  goToTime,
}: {
  defaultValues: QuizFormState;
  goToTime: (value: number | string) => void;
}) => {
  const { playlistId } = useParams();
  const { openModal, showNotification } = useActions();

  const [updateQuiz, updateStatus] = useUpdatePartialQuizByIdMutation();

  const methods = useForm<QuizFormState>({ defaultValues });

  const handleSubmitQuiz = async (value: QuizFormState) => {
    try {
      await updateQuiz({
        playlistId: playlistId || '',
        publicId: defaultValues.publicId || '',
        body: transformToQuizFetchedData(value),
      }).unwrap();

      showNotification({
        text: 'Квиз успешно сохранен',
        severity: 'success',
      });
    } catch (err) {
      console.log(err);
      showNotification({
        text: 'Произошла ошибка при сохранении квиза',
        severity: 'error',
      });
    }
  };

  const handleDeleteQuiz = () => {
    openModal({ active: 'RemoveQuiz' });
  };

  const { fields, remove, append } = useFieldArray<QuizFormState, 'quizzes', 'key'>({
    name: 'quizzes',
    keyName: 'key',
    control: methods.control,
  });

  const handleAddQuestionBlock = () => {
    append({
      chapter: { text: '', title: '', start: '00:00:00' },
      quiz: [
        // {
        //   question: '',
        //   answers: [
        //     { answer: '', isCorrect: false },
        //     { answer: '', isCorrect: false },
        //     { answer: '', isCorrect: false },
        //     { answer: '', isCorrect: false },
        //   ],
        // },
      ],
    });
  };

  return (
    <FormProvider {...methods}>
      <QuizPageStyledForm onSubmit={methods.handleSubmit(handleSubmitQuiz)}>
        {fields.map(({ key }, index) => (
          <QuizBlockWrapper key={key}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
              {/* <TitleH4>{t('admin.title')}</TitleH4> */}
              <Button
                theme="transparent"
                style={{
                  padding: '4px 8px',
                  color: theme.colors.text.white_80,
                }}
                variant="withIcon"
                onClick={() => remove(index)}
              >
                <CrossIcon />
              </Button>
            </div>
            <QuizBlock index={index} goToTime={goToTime} />
          </QuizBlockWrapper>
        ))}
        <Button variant="outlined" theme="inline" onClick={handleAddQuestionBlock} disabled={updateStatus.isLoading}>
          Добавить раздел
        </Button>
        {fields.length > 0 && (
          <Buttons>
            <Button type="submit" theme="white" disabled={updateStatus.isLoading}>
              Сохранить
            </Button>
            <Button onClick={handleDeleteQuiz} disabled={updateStatus.isLoading}>
              Удалить
            </Button>
          </Buttons>
        )}
        {updateStatus.isLoading && <FullScreenLoader />}
      </QuizPageStyledForm>
      <RemoveQuiz playlistId={playlistId || ''} publicId={defaultValues.publicId || ''} />
    </FormProvider>
  );
};
